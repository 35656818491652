<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
              disable-pagination
              :items-per-page="0"
              :items="signerProposalsListEntries"
              :headers="signerProposalsListHeaders">
            <template v-if="!title" v-slot:headers="{ columns }">
              <tr>
                <template v-for="column in columns" :key="column.key">
                  <!--  no header  -->
                </template>
              </tr>
            </template>
            <template #bottom></template>
            <template v-slot:body="{ items }">
              <tr v-for="item in items" :key="item.signerAddress">
                <td>
                  <div class="d-flex align-center">
                  <span class="text-no-wrap">

                    <v-btn color="primary" variant="outlined" class="mr-3 button-text-no-caps" :disabled="item.userHasVoted === true" @click="$emit( 'approve-proposal', item.proposalId)">
                       <v-icon large icon="mdi-vote"/>
                      Approve ({{ item.approvalCount }}/{{ organisationEntry?.effectivelyRequiredSignaturesForUserManagementOperations }})
                    </v-btn>
                    <v-tooltip text="Tooltip">
                        <template v-slot:activator="{ props }">
                          <span v-bind="props" class="text-no-wrap">
                              {{ itemPrefix }}
                              <v-icon large icon="mdi-account"/>
                              {{ item.signersUserProfileName }}
                              <span style="opacity: 0.5;">{{ item.signersUserProfileEmail }}</span>
                          </span>
                        </template>
                      <p v-if="item.signersUserProfileName !== item.signerAddress"> {{ item.signersUserProfileName }}</p>
                      <p> {{ item.signersUserProfileEmail }}</p>
                      <p> {{ item.signerAddress }}</p>
                    </v-tooltip>

                  </span>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  components: {},
  data () {
    return {
      signerProposalsListHeaders: [
        { title: this.title, value: 'adminsUserProfileName' },
      ]
    };
  },
  props: {
    title: String,
    signerProposalsListEntries: Array,
    organisationEntry: null,
    itemPrefix: String
  },
  methods: {},
};
</script>

<style scoped>
</style>