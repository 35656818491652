<template>
  <div>
    <v-row align="center" class="px-3 py-2">
      <v-col disabled cols="auto" style="opacity: 0">
        <v-icon color="primary" @click="loadUserProfile()">mdi-refresh</v-icon>
      </v-col>
      <v-spacer/>
      <v-col cols="auto">
        <h4 color="primary">User Profile</h4>
      </v-col>
      <v-spacer/>
      <v-col cols="auto">
        <v-icon color="primary" @click="loadUserProfile()">mdi-refresh</v-icon>
      </v-col>
    </v-row>

    <v-text-field class="ml-0 mr-0 mt-6" label="Name" v-model="userProfileName"></v-text-field>
    <v-text-field class="ml-0 mr-0" label="E-Mail" v-model="userProfileEmail"></v-text-field>
    <v-text-field class="ml-0 mr-0 mb-0" label="Comment" v-model="userProfileComment"></v-text-field>

    <v-row justify="end">
      <v-col cols="auto">
        <v-btn class="mt-5 button-text-no-caps" color="primary" @click="storeUserProfile()">Save Profile</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dao from '../OrganisationContractDAO'

export default {
  data () {
    return {
      userProfileName: null,
      userProfileEmail: null,
      userProfileComment: null,
    };
  },
  mounted () {
    this.loadUserProfile();
  },
  methods: {
    async loadUserProfile () {
      const userProfile = await dao.getOwnUserProfile();
      this.userProfileName = userProfile.name;
      this.userProfileEmail = userProfile.email;
      this.userProfileComment = userProfile.comment;
    },
    async storeUserProfile () {
      this.$emit('stored')
      await dao.setOwnUserProfile(this.userProfileName, this.userProfileEmail, this.userProfileComment);
    }
  },
};
</script>

<style scoped>


</style>