<template>
  <div>
    <v-row align="center" class="px-3 py-2 mb-3" @mouseenter="isHovering = true" @mouseleave="isHovering = false">
      <h3>Organisations</h3>
      <v-progress-circular v-if="loading" indeterminate color="primary" :size="16" :width="2" class="ml-5"></v-progress-circular>
      <v-btn rounded @click="updateOrganizationList()" v-if="isHovering && !loading" color="primary" variant="text" style="min-width: 20px" class="button-text-no-caps">
        <v-icon icon="mdi-refresh"/>
      </v-btn>
      <v-spacer/>
      <v-btn color="primary" class="button-text-no-caps" variant="outlined" @click="showNewOrganisationDialog()">
        <v-icon icon="mdi-plus-thick"/>
      </v-btn>

    </v-row>

    <div>
      <v-card>
        <v-data-table
            disable-pagination
            :items-per-page="0"
            :items="organizationTableItems"
            :headers="organizationTableItemsListHeaders"
            item-key="id"
            class="elevation-1"
            v-model="selectedOrganisationId"
        >
          <template v-slot:headers="{ columns }">
            <tr>
              <template v-for="column in columns" :key="column.key">
                <!--  no header  -->
              </template>
            </tr>
          </template>
          <template v-slot:body="{ items }">
            <tr v-for="item in items" :key="item.adminAddress">
              <td @click="selectOrganisation(item.id)" :class="selectedOrganisationId.includes(item.id)?'text-primary':''">
                <div class="d-flex align-center">
                  <v-icon large class="mr-2">
                    mdi-domain
                  </v-icon>
                  <span>{{ item.name }}</span>
                </div>
              </td>
            </tr>
          </template>
          <template #bottom></template>
        </v-data-table>
      </v-card>
    </div>
    <div/>


    <!-- Add Organization Dialog -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">New Organization</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Organization Name" v-model="newOrganizationName"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" class="button-text-no-caps" @click="hideNewOrganisationDialog()">Cancel</v-btn>
          <v-btn color="blue darken-1" class="button-text-no-caps" @click="createNewOrganization()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import dao from '../OrganisationContractDAO'

export default {
  components: {},
  mounted () {
    this.updateOrganizationList()
  },
  props: {
    organisationId: String
  },
  data () {
    return {
      selectedOrganisationId: [],
      dialog: false,
      newOrganizationName: '',
      organisations: [],
      organizationTableItems: [],
      organizationTableItemsListHeaders: [
        { title: 'Organisations', value: 'name' },
        // { title: 'E-Mail', value: 'userProfileEmail' },
        // { title: 'Comment', value: 'userProfileComment' },
        // { title: 'Address', value: 'adminAddress' },
      ],
      loading: false,
      isHovering: false
    };
  },
  methods: {
    showNewOrganisationDialog () {
      this.dialog = true;
    },
    hideNewOrganisationDialog () {
      this.dialog = false;
    },
    async createNewOrganization () {
      await dao.createNewOrganization(this.newOrganizationName);
      this.newOrganizationName = '';
      this.hideNewOrganisationDialog();
      await this.updateOrganizationList();
    },
    async updateOrganizationList () {
      this.loading = true
      let newOrganizationTableItems = [];
      if( this.organizationTableItems.length === 0){
         newOrganizationTableItems = this.organizationTableItems;
      }
      try {
        let signerAddress = dao.signerAddressString()
        this.organizations = await dao.getRelevantOrganizations();

        for (const organization of this.organizations) {
          const isAdmin = await dao.isAdminOfOrganization(organization.organizationId, signerAddress);
          newOrganizationTableItems.push({
            id: organization.organizationId,
            name: organization.name,
            hasAdminPermissions: isAdmin
          });
        }
      } finally {
        this.organizationTableItems = newOrganizationTableItems
        this.loading = false;
      }
    },
    selectOrganisation (orgId) {
      console.log('SELECT ' + orgId);
      this.$emit('organization-selected', orgId);
      this.selectedOrganisationId = [orgId];
    }
  },
};
</script>
