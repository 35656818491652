<template>
  <div class="">
    <div style="box-shadow: 0px -3px 6px -3px rgba(0,0,0,.2) inset;" class="pb-5">
      <v-container class="mb-5 mt-6" style="max-width: 1000px">
        <OrganisationChooser @organization-selected="handleSelectionChanged"/>
      </v-container>
    </div>
    <div >
<!--      <v-divider class="bg-white"/>-->
      <v-container class="mt-5" style="max-width: 1000px">
        <div class="px-0 py-2">
          <OrganisationEditor :organisation-id="organisationId" v-if="hasSelection"/>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>

import OrganisationEditor from '@/components/OrganisationEditor.vue'
import OrganisationChooser from '@/components/OrganisationChooser.vue'

export default {
  components: { OrganisationEditor, OrganisationChooser },
  data () {
    return {
      organisationId: String,
      hasSelection: false
    };
  },
  mounted () {
    this.organisationId = null;
  },
  methods: {
    handleSelectionChanged (newSelection) {
      this.hasSelection = false;
      this.organisationId = newSelection;
      this.hasSelection = true;
    },
  },
};
</script>
