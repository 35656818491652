<template>
  <div>
    <v-row align="center" class="px-3 py-2" @mouseenter="isHovering = true" @mouseleave="isHovering = false">
      <h3 class="pl-0">
        <v-icon icon="mdi-domain" class="mr-2"/>
        {{ organisationEntry?.name }}
      </h3>

      <v-progress-circular v-if="loading" indeterminate color="primary" :size="16" :width="2" class="ml-4"></v-progress-circular>
      <v-btn rounded @click="loadAll()" v-if="isHovering && !loading" color="primary" variant="text" style="min-width: 20px" class="button-text-no-caps">
        <v-icon icon="mdi-refresh"/>
      </v-btn>

      <v-spacer/>
      <v-tooltip location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" v-if="organisationEntry" @click="showDialogForMessageSigning = true" color="#71CC32" variant="outlined" class="pl-3 pr-4 button-text-no-caps">
            <v-icon icon="mdi-check-decagram" class="mr-3"/>
            Verification & Signing
          </v-btn>
        </template>
        Test signing and verify message manually for this organisation.
      </v-tooltip>

      <v-tooltip location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" @click="showDialogOrgSetting = true" color="primary" variant="outlined" class="ml-2 button-text-no-caps">
            <v-icon icon="mdi-cog"/>
          </v-btn>
        </template>
        <p>Require multiple admins to approve changes for increased security: </p>
        <p>Adding/Removing Admins: {{ organisationEntry?.effectivelyRequiredSignaturesForAdminOperations }}</p>
        <p>Adding/Removing Signers: {{ organisationEntry?.effectivelyRequiredSignaturesForUserManagementOperations }}</p>
      </v-tooltip>
    </v-row>


    <v-row v-if="!loading">

      <v-col>
        <v-row class="mt-3">
          <v-col cols="auto" align-self="center">
            <h4 class="px-4 ">Authorized Signers</h4>
          </v-col>
        </v-row>
        <SignerTable
            :signers-list-entries="signersListEntries"
            @proposeSignerRemoval="proposeSignerRemoval"
            @removeMyselfAsSignerWithoutApproval="removeMyselfAsSignerWithoutApproval"
        />
        <v-row class="mt-5">
          <v-col v-if="organisationEntry?.effectivelyRequiredSignaturesForAdminOperations > 1" cols="auto" align-self="center">
            <h4 class="px-4 ">Proposals</h4>
          </v-col>
          <v-col cols="fill">
            <RowButton
                v-if="organisationEntry?.effectivelyRequiredSignaturesForAdminOperations <= 1"
                buttonText="Add Signer ..."
                @button-clicked="showDialogForNewSigner = true"
            />
            <RowButton
                v-if="organisationEntry?.effectivelyRequiredSignaturesForAdminOperations > 1"
                buttonText="Propose new Signer ..."
                @button-clicked="showDialogForNewSignerProposal = true"
            />
          </v-col>
        </v-row>

        <SignerProposalTable
            v-if="signerAddProposalsListEntries?.length > 0"
            item-prefix="Add Signer"
            :signer-proposals-list-entries="signerAddProposalsListEntries"
            :organisation-entry="organisationEntry"
            @approveProposal="approveNewSignerProposal(organisationId, $event)"
        />

        <SignerProposalTable
            v-if="signerRemoveProposalsListEntries?.length > 0"
            item-prefix="Remove Signer"
            :signer-proposals-list-entries="signerRemoveProposalsListEntries"
            :organisation-entry="organisationEntry"
            @approveProposal="approveSignerRemovalProposal(organisationId, $event)"
        />
      </v-col>
    </v-row>

  </div>

  <v-dialog v-model="showDialogOrgSetting" max-width="600px">
    <v-card class="pb-5 px-7">
      <v-col class="pt-6">
        <v-card-title class="pb-4">
          <span class="headline"> <v-icon icon="mdi-domain" class="mr-2"/>{{ organisationEntry?.name }}</span>
          <v-btn icon="mdi-pencil" @click="showDialogRenameOrganisation = true" variant="plain" color="primary" class="ml-3"/>
        </v-card-title>
        <v-col cols="auto" align-self="center">
          <h4 class="px-1">Administrators</h4>
        </v-col>
        <AdminTable
            :admins-list-entries="adminsListEntries"
            @proposeAdminRemoval="proposeAdminRemoval"
            @removeMyselfAsAdminWithoutApproval="removeMyselfAsAdminWithoutApproval"
            class="mb-2"
        />
        <RowButton
            v-if="organisationEntry?.effectivelyRequiredSignaturesForAdminOperations <= 1"
            buttonText="Add Admin ..."
            @button-clicked="showDialogForNewAdmin = true"
        />

        <v-col cols="auto" align-self="center" class="mt-8 mb-4" v-if="organisationEntry?.effectivelyRequiredSignaturesForAdminOperations > 1">
          <v-row align="center">
            <h4 class="px-1 pl-4">Proposals</h4>
            <RowButton

                buttonText="Propose new Admin ..."
                @button-clicked="showDialogForNewAdminProposal = true"
            />
          </v-row>
        </v-col>
        <AdminProposalTable
            v-if="adminAddProposalsListEntries?.length > 0"
            item-prefix="Add Admin"
            :admin-proposals-list-entries="adminAddProposalsListEntries"
            :organisation-entry="organisationEntry"
            @approveProposal="approveNewAdminProposal(organisationId, $event)"
        />

        <AdminProposalTable
            v-if="adminRemoveProposalsListEntries?.length > 0"
            item-prefix="Remove Admin"
            :admin-proposals-list-entries="adminRemoveProposalsListEntries"
            :organisation-entry="organisationEntry"
            @approveProposal="approveRemovalAdminProposal(organisationId, $event)"
        />
      </v-col>
      <v-col class="pb-6">
        <MultiSigEditor
            :organisation-id="organisationId"
            :organisation-name="organisationEntry?.name"/>
      </v-col>
    </v-card>
  </v-dialog>
  <v-dialog v-model="showDialogForNewSignerProposal" max-width="600px">
    <WalletChoserPanel
        @create-admin="showDialogForNewSignerProposal = false; proposeNewSigner($event)"
        @cancel="showDialogForNewSignerProposal = false;"
        panelTitle="Propose new Signer"/>
  </v-dialog>

  <v-dialog v-model="showDialogForNewSigner" max-width="600px">
    <WalletChoserPanel
        @create-admin="showDialogForNewSigner = false; addSignerWithoutApproval($event)"
        @cancel="showDialogForNewSigner = false;"
        panelTitle="Add new Signer"/>
  </v-dialog>

  <v-dialog v-model="showDialogForNewAdminProposal" max-width="600px">
    <WalletChoserPanel
        @create-admin="showDialogForNewAdminProposal = false; proposeNewAdmin($event)"
        @cancel="showDialogForNewAdminProposal = false;"
        panelTitle="Propose new Admin"/>
  </v-dialog>

  <v-dialog v-model="showDialogForNewAdmin" max-width="600px">
    <WalletChoserPanel
        @create-admin="showDialogForNewAdmin = false; addAdminWithoutApproval($event)"
        @cancel="showDialogForNewAdmin = false;"
        panelTitle="Add new Admin"/>
  </v-dialog>

  <v-dialog v-model="showDialogForMessageSigning" style="max-width: 1000px">
    <MessageSigningPanel class="pa-10"
        :organisation-id="organisationId"/>
  </v-dialog>


  <v-dialog v-model="showDialogRenameOrganisation" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">New Organization</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field label="Organization Name" v-model="newOrganizationName"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" class="button-text-no-caps" @click="showDialogRenameOrganisation = false">Cancel</v-btn>
        <v-btn color="blue darken-1" class="button-text-no-caps" @click="showDialogRenameOrganisation = false; setOrganisationName()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dao from '../OrganisationContractDAO'
import MultiSigEditor from '@/components/MultiSigEditor.vue'
import MessageSigningPanel from '@/components/MessageSigningPanel.vue'
import WalletChoserPanel from '@/components/WalletChoserPanel.vue'
import AdminTable from '@/components/tables/AdminTable.vue'
import SignerTable from '@/components/tables/SignerTable.vue'
import AdminProposalTable from '@/components/tables/AdminProposalTable.vue'
import RowButton from '@/components/basics/RowButton.vue'
import SignerProposalTable from '@/components/tables/SignerProposalTable.vue'

export default {
  components: { MessageSigningPanel, MultiSigEditor, WalletChoserPanel, AdminTable, AdminProposalTable, SignerTable, RowButton, SignerProposalTable },
  data () {
    return {
      tab: 'signers',
      loading: false,
      isHovering: false,
      organisationEntry: null,
      adminsListEntries: [],
      adminAddProposalsListEntries: [],
      adminRemoveProposalsListEntries: [],
      signersListEntries: [],
      signerAddProposalsListEntries: [],
      signerRemoveProposalsListEntries: [],
      // Dialogs
      showDialogForMessageSigning: false,
      showDialogForNewAdmin: false,
      showDialogForNewAdminProposal: false,
      showDialogForNewSigner: false,
      showDialogForNewSignerProposal: false,
      showDialogOrgSetting: false,
      showDialogRenameOrganisation: false,
      newOrganizationName: ''
    };
  },
  props: {
    organisationId: String
  },
  watch: {
    organisationId () {
      this.loadAll();
    },
  },
  mounted () {
    this.loadAll();
  },
  methods: {
    async setOrganisationName(){
      dao.setOrganizationName(this.organisationId, this.newOrganizationName)
    },
    async loadAll () {
      this.loading = true;
      // org
      await this.loadOrganisationEntry();
      // signer
      await this.loadSigners();
      await this.loadAddSignerProposals();
      await this.loadRemoveSignerProposals();
      // admin
      await this.loadAdmins();
      await this.loadAddAdminsProposals();
      await this.loadRemoveAdminsProposals();
      this.loading = false;
    },
    async loadSigners () {
      const signers = await dao.getRelevantSignersOfOrganization(this.organisationId);
      this.signersListEntries = signers.map(value => {
        return {
          isCurrentlySignerOfOrganization: value.isCurrentlySignerOfOrganization,
          userProfileName: (value.userProfile.name?.trim() || (value.address + '')),
          userProfileEmail: value.userProfile.email,
          userProfileComment: value.userProfile.comment,
          signerAddress: value.address
        }
      })
    },
    async loadAddSignerProposals () {
      const addSignerProposals = await dao.getOpenSignerAddingProposals(this.organisationId);
      this.signerAddProposalsListEntries = addSignerProposals.map(value => {
        return {
          signersUserProfileName: (value.signersUserProfile.name?.trim() || (value.signerToAdd + '')),
          signersUserProfileEmail: value.signersUserProfile.email,
          signersUserProfileComment: value.signersUserProfile.comment,
          signerAddress: value.signerToAdd + '',
          approvalCount: value.approvalCount + '',
          userHasVoted: value.userHasVoted
        }
      })
    },
    async loadRemoveSignerProposals () {
      const removeSignerProposals = await dao.getOpenSignerRemovalProposals(this.organisationId);
      this.signerRemoveProposalsListEntries = removeSignerProposals.map(value => {
        return {
          signersUserProfileName: (value.signersUserProfile.name?.trim() || (value.signerToRemove + '')),
          signersUserProfileEmail: value.signersUserProfile.email,
          signersUserProfileComment: value.signersUserProfile.comment,
          signerAddress: value.signerToRemove + '',
          approvalCount: value.approvalCount + '',
          userHasVoted: value.userHasVoted
        }
      })
    },
    async loadAdmins () {
      const admins = await dao.getAdminsOfOrganisation(this.organisationId);
      const ownAddress = await dao.signerAddressString();
      this.adminsListEntries = admins.map(value => {
        return {
          userProfileName: (value.userProfile.name?.trim() || (value.address + '')),
          userProfileEmail: value.userProfile.email,
          userProfileComment: value.userProfile.comment,
          adminAddress: value.address,
          isOwnUser: (ownAddress === value.address)
        }
      })
    },
    async loadAddAdminsProposals () {
      const addAdminProposals = await dao.getOpenAdminAddingProposals(this.organisationId);
      this.adminAddProposalsListEntries = addAdminProposals.map(value => {
        return {
          adminsUserProfileName: (value.adminsUserProfile.name?.trim() || (value.adminToAdd + '')),
          adminsUserProfileEmail: value.adminsUserProfile.email,
          adminsUserProfileComment: value.adminsUserProfile.comment,
          adminAddress: value.adminToAdd + '',
          approvalCount: value.approvalCount + '',
          userHasVoted: value.userHasVoted
        }
      })
    },
    async loadRemoveAdminsProposals () {
      const removeAdminProposals = await dao.getOpenAdminRemovalProposals(this.organisationId);
      this.adminRemoveProposalsListEntries = removeAdminProposals.map(value => {
        return {
          adminsUserProfileName: (value.adminsUserProfile.name?.trim() || (value.adminToRemove + '')),
          adminsUserProfileEmail: value.adminsUserProfile.email,
          adminsUserProfileComment: value.adminsUserProfile.comment,
          adminAddress: value.adminToRemove + '',
          approvalCount: value.approvalCount + '',
          userHasVoted: value.userHasVoted
        }
      })
    },
    async proposeNewAdmin (newAdmin) {
      await dao.proposeNewAdmin(this.organisationId, newAdmin);
    },
    async addAdminWithoutApproval (newAdminAddress) {
      await dao.addAdminWithoutApproval(this.organisationId, newAdminAddress);
    },
    async addSignerWithoutApproval (newAdminAddress) {
      await dao.addSignerWithoutApproval(this.organisationId, newAdminAddress);
    },
    async loadOrganisationEntry () {
      this.organisationEntry = await dao.getOrganizationEntry(this.organisationId);
      this.newOrganizationName = this.organisationEntry.name
    },
    async proposeNewSigner (newSigner) {
      await dao.proposeNewSigner(this.organisationId, newSigner);
    },
    async approveNewAdminProposal (proposalId) {
      await dao.approveNewAdminProposal(this.organisationId, proposalId);
    },
    async approveRemovalAdminProposal (proposalId) {
      await dao.approveAdminRemovalProposal(this.organisationId, proposalId);
    },
    async approveNewSignerProposal (proposalId) {
      await dao.approveNewSignerProposal(this.organisationId, proposalId);
    },
    async approveSignerRemovalProposal (proposalId) {
      await dao.approveSignerRemovalProposal(this.organisationId, proposalId);
    },
    async removeMyselfAsAdminWithoutApproval () {
      await dao.removeMyselfAsAdminWithoutApproval(this.organisationId);
    },
    async removeMyselfAsSignerWithoutApproval () {
      await dao.removeMyselfAsSignerWithoutApproval(this.organisationId);
    },
    async proposeAdminRemoval (adminToRemove) {
      await dao.proposeAdminRemoval(this.organisationId, adminToRemove);
    },
    async proposeSignerRemoval (signerToRemove) {
      await dao.proposeSignerRemoval(this.organisationId, signerToRemove);
    }
  },
};
</script>

<style scoped>
.horizontal-shadow {
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2); /* Adjust as needed */
}

</style>