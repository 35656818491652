<template>
  <v-row align="center">
    <v-col class="d-flex justify-start align-center">
      <v-card-text class="flex-grow-1">
      </v-card-text>
    </v-col>

    <v-col cols="auto">
      <v-btn color="primary" variant="outlined" class="button-text-no-caps px-3 mr-2" rounded @click="showEditorDialog = true">
        <v-icon icon="mdi-account"/>
        {{ name }}
      </v-btn>

      <v-tooltip location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" rounded color="primary" variant="outlined" class="button-text-no-caps px-3" style="min-width: 20px;" @click="showWalletDialog()">
            <v-icon icon="mdi-wallet-bifold-outline"/>
            <div v-if="hasNoName()">
              {{ formatAddressToShortString(address) }}
            </div>
          </v-btn>
        </template>
        <p>{{ address }} </p>
      </v-tooltip>
    </v-col>
  </v-row>

  <v-dialog v-model="showEditorDialog" max-width="600px">
    <v-card class="pa-7">
      <UserProfileEditor @stored="showEditorDialog=false"></UserProfileEditor>
    </v-card>
  </v-dialog>
</template>

<script>
import dao from '../OrganisationContractDAO'
import UserProfileEditor from '@/components/UserProfileEditor.vue'

export default {
  mounted () {
    this.loadUserProfile();
  },
  components: {
    UserProfileEditor
  },
  data () {
    return {
      showEditorDialog: false,
      address: null,
      name: null,
      email: null,
      comment: null,
      organizationTableItems: [],
      organisationTableItemsHeaders: [
        { title: 'ID', value: 'organisationId' },
        { title: 'Organisation', key: 'name' },
      ]
    };
  },
  methods: {
    async loadUserProfile () {
      const userProfile = await dao.getOwnUserProfile();
      this.name = userProfile.name;
      this.email = userProfile.email;
      this.comment = userProfile.comment;
      this.address = await dao.signerAddressString();
    },
    async storeUserProfile () {
      await dao.setOwnUserProfile(this.name, this.email, this.comment);
    },
    formatAddressToShortString (address) {
      if (address) {
        return address.substr(0, 4) + '...' + address.substr(address.length - 3, address.length)
      } else {
        return ''
      }
    },
    showWalletDialog () {
      dao.showWalletDialog()
    },
    hasNoName () {
      return !this.name || this.name.length === 0
    }
  },
};
</script>

<style scoped>
/* CSS to remove the underline by default */
.email-link {
  color: rgb(var(--v-theme-primary));
  text-decoration: none;
}

/* CSS to add underline on hover */
.email-link:hover {
  text-decoration: underline;
}
</style>