import { ethers } from 'ethers';
import OrganizationManagerABI from './assets/OrganizationManagerABI.json'
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalProvider } from '@web3modal/ethers/vue'
import * as util from 'util'

const contractAddress = '0x1E621acfA74072b0a0CA1bCE4285ed60Aa259892';
const projectId = '892f2a84882f8ae6d543daa44bbcafbc'
const infuraKey = '05cc2f56c7a34d3e859f2dc54d52a6ec'
// const mainnet = {
//   chainId: 1,
//   name: 'Ethereum',
//   currency: 'ETH',
//   explorerUrl: 'https://etherscan.io',
//   rpcUrl: 'https://cloudflare-eth.com'
// }
const mumbai = {
  chainId: 80001,
  name: 'Polygon Mumbai',
  currency: 'MATIC',
  explorerUrl: 'https://mumbai.polygonscan.com',
  rpcUrl: `https://polygon-mumbai.infura.io/v3/${infuraKey}` // RPC URL for Mumbai Testnet
}
const metadata = {
  name: 'InpugLock',
  description: 'Visit inputlock.com',
  url: 'http://localhost:8080/', // origin must match your domain & subdomain
  icons: ['http://localhost:8080/']
}
let walletProvider = undefined
let contract = null
let initialized = false

async function open () {
  const { open } = useWeb3Modal()
  open()
}

async function close () {
  const { close } = useWeb3Modal()
  close()
}

async function showWalletDialog () {
  const { open } = useWeb3Modal()
  open({ view: 'Account' })
}

async function isWalletConnected () {
  await initWallet()
  return walletProvider !== undefined
}

async function initWallet () {
  if (!initialized) {
    initialized = true
    console.error('WALLET FULLY INITIALIZED')
    await createWeb3Modal({
      ethersConfig: defaultConfig({ metadata }),
      chains: [mumbai],
      themeMode: 'light',
      projectId,
      enableAnalytics: true,
      chainImages: {
        1: 'https://my.images.com/eth.png'
      }
    })
  }
  // if (!walletProvider) {
  walletProvider = await useWeb3ModalProvider().walletProvider.value;
  // }
}

async function signMessage (message) {
  const browserProvider = new ethers.BrowserProvider(walletProvider);
  const signer = await browserProvider.getSigner();
  return await signer.signMessage(message);
}

async function getVerifiedSigner (message, signature) {
  const recoveredAddress = ethers.verifyMessage(message, signature);
  console.log('verifyMessage' + recoveredAddress + '  ' + signerAddress);
  return recoveredAddress.toString()
}

async function getWritableContract () {
  if (contract) {
    return contract;
  }
  await initWallet();
  const browserProvider = new ethers.BrowserProvider(walletProvider);
  const signer = await browserProvider.getSigner();
  contract = await new ethers.Contract(contractAddress, OrganizationManagerABI, signer);
  return contract;
}

async function getReadableContract () {
  return getWritableContract();
  // DOESNT WORK FOR SOME REASON
  // if (this.contract) {
  //   return this.contract;
  // }
  // const provider = new ethers.InfuraProvider('matic-mumbai', '05cc2f56c7a34d3e859f2dc54d52a6ec');
  // // const provider = new ethers.JsonRpcProvider(mumbai.rpcUrl);
  // return new ethers.Contract(this.contractAddress, OrganizationManagerABI, await provider);
}

async function createNewOrganization (newOrganisationsName) {
  const writableContract = await getWritableContract();
  let createOrganisationTX = await writableContract.createOrganization(newOrganisationsName);
  console.log('createOrganization() Org: ' + createOrganisationTX + ' for ' + await signerAddress())
}

async function setOrganizationName (orgId, newOrganisationsName) {
  const writableContract = await getWritableContract();
  let createOrganisationTX = await writableContract.setOrganizationName(orgId, newOrganisationsName);
  console.log('setOrganizationName() Org: ' + createOrganisationTX + ' for ' + await signerAddress())
}

async function getOwnUserProfile () {
  let userAddress = await signerAddress()
  return await getUserProfile(userAddress)
}

async function getUserProfile (userAddress) {
  if (!await isWalletConnected()) {
    return { name: '[not logged in]' }
  }
  const readableContract = await getReadableContract();
  const userProfile = await readableContract.getUserProfile(userAddress);
  console.log('loadUserProfile() Profile: ' + JSON.stringify(userProfile));
  return {
    name: userProfile.name,
    email: userProfile.email,
    comment: userProfile.comment
  };
}

async function setOwnUserProfile (name, email, comment) {
  const writableContract = await getWritableContract();
  await writableContract.setUserProfile(name, email, comment);
}

async function getRelevantOrganizations () {
  let readableContract = await getReadableContract()
  const organizationList = await readableContract.getRelevantOrganisationsOfUser(await signerAddress())
  const result = await Promise.all(organizationList.map(async orgId => {
    return await getOrganizationEntry(orgId);
  }));
  console.log('listOrganizations(): ' + JSON.stringify(result));
  return result;
}

async function getOrganizationEntry (orgId) {
  let readableContract = await getReadableContract()
  let orgEntry = await readableContract.getOrganizationEntry(orgId)
  return {
    organizationId: orgId.toString(),
    name: orgEntry.name,
    requiredSignaturesForAdminOperations: orgEntry.requiredSignaturesForAdminOperations.toString(),
    requiredSignaturesForUserManagementOperations: orgEntry.requiredSignaturesForUserManagementOperations.toString(),
    effectivelyRequiredSignaturesForAdminOperations: orgEntry.effectivelyRequiredSignaturesForAdminOperations.toString(),
    effectivelyRequiredSignaturesForUserManagementOperations: orgEntry.effectivelyRequiredSignaturesForUserManagementOperations.toString()
  }
}

async function getRelevantSignersOfOrganization (organisationId) {
  let readableContract = await getReadableContract();
  const relevantSignerAddresses = await readableContract.getRelevantSignersOfOrganization(organisationId);
  const result = await Promise.all(relevantSignerAddresses.map(async signerAddress => {
    const isCurrentlySignerOfOrganization = await readableContract.isCurrentlySignerOfOrganization(organisationId, signerAddress);
    const userProfile = await getUserProfile(signerAddress);
    return {
      address: signerAddress,
      isCurrentlySignerOfOrganization: isCurrentlySignerOfOrganization,
      userProfile: userProfile
    }
  }));
  console.log('listSignersOfOrganizations(): ' + JSON.stringify(result));
  return result;
}

async function getOpenAdminAddingProposals (organisationId) {
  let readableContract = await getReadableContract();
  const openAdminProposals = await readableContract.getOpenAdminAddingProposals(organisationId);
  return await Promise.all(openAdminProposals.map(async proposal => {
    const newAdminsUserProfile = await getUserProfile(proposal.adminToAdd);
    return {
      proposalId: proposal.proposalId,
      adminToAdd: proposal.adminToAdd,
      approvalCount: proposal.approvalCount,
      userHasVoted: proposal.userHasVoted,
      adminsUserProfile: newAdminsUserProfile
    }
  }));
}

async function getOpenAdminRemovalProposals (organisationId) {
  let readableContract = await getReadableContract();
  const openAdminRemovalProposals = await readableContract.getOpenAdminRemovalProposals(organisationId);
  return await Promise.all(openAdminRemovalProposals.map(async proposal => {
    const newAdminsUserProfile = await getUserProfile(proposal.adminToRemove);
    return {
      proposalId: proposal.proposalId,
      adminToRemove: proposal.adminToRemove,
      approvalCount: proposal.approvalCount,
      userHasVoted: proposal.userHasVoted,
      adminsUserProfile: newAdminsUserProfile
    }
  }));
}

async function getOpenSignerAddingProposals (organisationId) {
  let readableContract = await getReadableContract();
  const proposal = await readableContract.getOpenSignerAddingProposals(organisationId);
  const result = await Promise.all(proposal.map(async proposal => {
    const signersUserProfile = await getUserProfile(proposal.signerToAdd);
    return {
      proposalId: proposal.proposalId,
      signerToAdd: proposal.signerToAdd,
      approvalCount: proposal.approvalCount,
      userHasVoted: proposal.userHasVoted,
      signersUserProfile: signersUserProfile
    }
  }));
  console.log('getOpenSignerAddingProposals() ---===>-===: ' + util.inspect(proposal));
  return result;
}

async function getOpenSignerRemovalProposals (organisationId) {
  let readableContract = await getReadableContract();
  const openSignerRemovalProposals = await readableContract.getOpenSignerRemovalProposals(organisationId);
  const result = await Promise.all(openSignerRemovalProposals.map(async proposal => {
    const signersUserProfile = await getUserProfile(proposal.signerToRemove);
    return {
      proposalId: proposal.proposalId,
      signerToRemove: proposal.signerToRemove,
      approvalCount: proposal.approvalCount,
      userHasVoted: proposal.userHasVoted,
      signersUserProfile: signersUserProfile
    }
  }));
  console.log('getOpenSignerRemovalProposals(): ' + util.inspect(openSignerRemovalProposals));
  return result;
}

async function getAdminsOfOrganisation (organisationId) {
  let readableContract = await getReadableContract();
  const adminAddresses = await readableContract.getAdminsOfOrganization(organisationId);
  const result = await Promise.all(adminAddresses.map(async adminAddress => {
    const userProfile = await getUserProfile(adminAddress);
    return {
      address: adminAddress,
      userProfile: userProfile
    }
  }));
  console.log('listAdminsOfOrganizations(): ' + JSON.stringify(adminAddresses));
  return result;
}

async function isSignerOfOrganizationAtTimestamp (orgId, signerAddress, timestamp) {
  let readableContract = await getReadableContract();
  return await readableContract.isSignerOfOrganizationAtTimestamp(orgId, signerAddress, timestamp);
}

async function isCurrentlySignerOfOrganization (orgId, signerAddress) {
  let readableContract = await getReadableContract();
  return await readableContract.isCurrentlySignerOfOrganization(orgId, signerAddress);
}

async function signerAddress () {
  await initWallet()
  if (!await isWalletConnected()) {
    return undefined
  } else {
    const browserProvider = new ethers.BrowserProvider(walletProvider);
    const signer = await browserProvider.getSigner();
    return await signer.getAddress();
  }
}

async function proposeNewAdmin (orgId, newAdmin) {
  const writableContract = await getWritableContract();
  let tx = await writableContract.proposeNewAdmin(orgId, newAdmin);
  console.log('createAdminProposal() Proposal TX: ' + tx)
}

async function addAdminWithoutApproval (orgId, newAdminAddress) {
  const writableContract = await getWritableContract();
  let tx = await writableContract.addAdminWithoutApproval(orgId, newAdminAddress);
  console.log('addAdminWithoutApproval() Proposal TX: ' + tx)
}

async function addSignerWithoutApproval (orgId, newSignerAddress) {
  const writableContract = await getWritableContract();
  let tx = await writableContract.addSignerWithoutApproval(orgId, newSignerAddress);
  console.log('addSignerWithoutApproval() Proposal TX: ' + tx)
}

async function approveNewAdminProposal (orgId, proposalId) {
  const writableContract = await getWritableContract();
  let createOrganisationTX = await writableContract.approveNewAdminProposal(orgId, proposalId);
  console.log('approveNewAdminProposal() Org: ' + createOrganisationTX + ' for ' + (await signerAddress()));
}

async function approveNewSignerProposal (orgId, proposalId) {
  const writableContract = await getWritableContract();
  let createOrganisationTX = await writableContract.approveNewSignerProposal(orgId, proposalId);
  console.log('approveNewSignerProposal() Org: ' + createOrganisationTX + ' for ' + (await signerAddress()));
}

async function approveSignerRemovalProposal (orgId, proposalId) {
  const writableContract = await getWritableContract();
  let createOrganisationTX = await writableContract.approveSignerRemovalProposal(orgId, proposalId);
  console.log('approveNewSignerProposal() Org: ' + createOrganisationTX + ' for ' + (await signerAddress()));
}

async function approveAdminRemovalProposal (orgId, proposalId) {
  const writableContract = await getWritableContract();
  let createOrganisationTX = await writableContract.approveAdminRemovalProposal(orgId, proposalId);
  console.log('approveNewSignerProposal() Org: ' + createOrganisationTX + ' for ' + (await signerAddress()));
}

async function setRequiredSignatures (orgId, requiredSignaturesForAdminOperations, requiredSignaturesForUserManagementOperations) {
  const writableContract = await getWritableContract();
  await writableContract.setRequiredSignatures(orgId, requiredSignaturesForAdminOperations, requiredSignaturesForUserManagementOperations);
}

async function removeMyselfAsAdminWithoutApproval (orgId) {
  const writableContract = await getWritableContract();
  await writableContract.removeMyselfAsAdminWithoutApproval(orgId);
}

async function removeMyselfAsSignerWithoutApproval (orgId) {
  const writableContract = await getWritableContract();
  await writableContract.removeMyselfAsSignerWithoutApproval(orgId);
}

async function proposeAdminRemoval (orgId, adminToRemove) {
  const writableContract = await getWritableContract();
  await writableContract.proposeAdminRemoval(orgId, adminToRemove);
}

async function proposeSignerRemoval (orgId, adminToRemove) {
  const writableContract = await getWritableContract();
  await writableContract.proposeSignerRemoval(orgId, adminToRemove);
}

async function proposeNewSigner (orgId, adminToRemove) {
  const writableContract = await getWritableContract();
  await writableContract.proposeNewSigner(orgId, adminToRemove);
}

async function isAdminOfOrganization (orgId, adminAddress) {
  let readableContract = await getReadableContract();
  return await readableContract.isAdminOfOrganization(orgId, adminAddress);
}

export default {
  addAdminWithoutApproval,
  addSignerWithoutApproval,
  approveAdminRemovalProposal,
  approveNewAdminProposal,
  approveNewSignerProposal,
  approveSignerRemovalProposal,
  createNewOrganization,
  setOrganizationName,
  getAdminsOfOrganisation,
  getOpenAdminAddingProposals,
  getOpenAdminRemovalProposals,
  getOpenSignerAddingProposals,
  getOpenSignerRemovalProposals,
  getOrganizationEntry,
  getRelevantOrganizations,
  getRelevantSignersOfOrganization,
  getVerifiedSigner,
  isAdminOfOrganization,
  isCurrentlySignerOfOrganization,
  isSignerOfOrganizationAtTimestamp,
  getOwnUserProfile,
  proposeAdminRemoval,
  proposeNewAdmin,
  proposeNewSigner,
  proposeSignerRemoval,
  removeMyselfAsAdminWithoutApproval,
  removeMyselfAsSignerWithoutApproval,
  setRequiredSignatures,
  signMessage,
  isWalletConnected,
  signerAddressString: async function () {
    return await signerAddress();
  },
  setOwnUserProfile,
  initWeb3: initWallet,
  open,
  close,
  showWalletDialog
}