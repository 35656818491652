<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
              disable-pagination
              :items-per-page="0"
              :items="adminsListEntries"
              :headers="adminsListHeaders"
          >
            <template v-if="!title" v-slot:headers="{ columns }">
              <tr>
                <template v-for="column in columns" :key="column.key">
                  <!--  no header  -->
                </template>
              </tr>
            </template>
            <template #bottom></template>
            <template v-slot:body="{ items }">
              <tr v-for="item in items" :key="item.adminAddress">
                <td>
                  <div class="d-flex align-center">
                    <v-icon large icon="mdi-shield-account"/>
                    <span class="ml-2">{{ item.userProfileName }} <span style="opacity: 0.5;">{{ item.userProfileEmail }}</span></span>
                  </div>
                </td>
                <td>
                  <div>
                    <v-btn flat>
                      <v-icon large color="primary" icon="mdi-dots-vertical"/>
                      <v-menu activator="parent">
                        <v-list>
                          <v-list-item v-if="item.isOwnUser" :key="1" :value="1" @click="$emit('removeMyselfAsAdminWithoutApproval', item.adminAddress)">
                            <v-list-item-title>Remove Yourself</v-list-item-title>
                          </v-list-item>
                          <v-list-item v-else :key="2" :value="2" @click="$emit( 'proposeAdminRemoval',item.adminAddress)">
                            <v-list-item-title>Propose Removal</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

    </v-row>
  </div>
</template>

<script>

export default {
  components: {},
  data () {
    return {
      // Admins
      adminsListHeaders: [
        { title: 'Admins', value: 'userProfileName' },
        { title: '', align: 'end', value: 'userProfileName',  width: '32px' },
      ]
    };
  },
  props: {
    adminsListEntries: Array
  },
  methods: {},
};
</script>

<style scoped>
</style>