<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{panelTitle}}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field label="Wallet Address" v-model="newAdminAddress"/>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" class="button-text-no-caps" @click="$emit('cancel')">Cancel</v-btn>
      <v-btn color="primary" class="button-text-no-caps" @click="$emit('create-admin', newAdminAddress)">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  data () {
    return {
      newAdminAddress: '',
    };
  },
  mounted () {
  },
  props: {
    panelTitle: String
  },
  methods: {},
};
</script>

<style scoped>
</style>