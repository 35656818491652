<template>
  <v-row justify="end">
    <v-col cols="auto">
      <v-btn color="primary" variant="outlined" flat class="button-text-no-caps ml-2" @click="$emit('buttonClicked')">{{ buttonText }}</v-btn>
    </v-col>
  </v-row>
</template>

<script>

export default {
  data () {
    return {
    };
  },
  props: {
    buttonText: String,
  },
};
</script>

<style scoped>
</style>