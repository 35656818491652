<template>
  <v-card>
    <v-card-title>
      <span class="headline">Message Verification & Signing</span>
    </v-card-title>
    <v-tabs
        v-model="tab"
        color="primary"
        align-tabs="center"
    >
      <v-tab :value="1">Verify</v-tab>
      <v-tab :value="2">Sign</v-tab>
    </v-tabs>
    <v-divider/>

    <div v-if="tab===1">
      <v-row align="center" class="px-3 py-2">
        <v-spacer/>
        <v-col cols="auto">
        </v-col>
      </v-row>
      <v-row align="center" class="px-3 py-2">
        <v-textarea v-model="message" label="Message"/>
      </v-row>
      <v-row align="center" class="px-3 py-2">
        <v-textarea v-model="signature" label="Signature"/>
      </v-row>
      <v-row justify="end">
        <v-col cols="auto">
          {{ verificationResult }}
        </v-col>
        <v-col cols="auto">
          <v-btn class="mt-2 button-text-no-caps" color="primary" @click="verify()">Verify</v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-if="tab===2">
      <v-row align="center" class="px-3 py-2">
        <v-spacer/>
        <v-col cols="auto">
        </v-col>
      </v-row>
      <v-row align="center" class="px-3 pt-2">
        <v-textarea v-model="message" label="Message"/>
      </v-row>
      <v-row justify="end">
        <v-col cols="auto">
          <v-btn class="button-text-no-caps" color="primary" @click="sign()">Sign</v-btn>
        </v-col>
      </v-row>
      <v-row align="center" class="px-3 py-2">
        <v-textarea v-model="signature" label="Signature" readonly/>
      </v-row>

    </div>

  </v-card>
</template>

<script>
import dao from '../OrganisationContractDAO'

export default {
  data () {
    return {
      message: '',
      signature: '',
      tab: 1,
      verificationResult: ''
    };
  },
  props: {
    organisationId: String
  },
  methods: {
    async sign () {
      this.signature = await dao.signMessage(this.message);
    },
    async verify () {
      let signerAddress = await dao.signerAddressString()
      let verifiedSignerAddress = await dao.getVerifiedSigner(this.message, this.signature, signerAddress)
      let isCurrentlySigner = dao.isCurrentlySignerOfOrganization(this.organisationId, verifiedSignerAddress);
      let signerInfo = isCurrentlySigner ? ' (currently signer of organisation)' : ' (currently not signer of organisation)';
      if (signerAddress === verifiedSignerAddress) {
        this.verificationResult = 'Valid for the current user! ' + verifiedSignerAddress + signerInfo;
      } else {
        this.verificationResult = 'Valid for user: ' + verifiedSignerAddress + signerInfo;
      }
    }
  },
};
</script>

<style scoped>


</style>