<template>
  <v-app>
    <v-main>
      <v-app-bar :elevation="5" app>
        <v-row>

          <v-col align="start" align-self="center">
            <v-img :src="betaSrc"
              contain
              width="111px"
                style="opacity: .8"
                color="primary"
              class="pl-5 v-col-auto"/>
            <v-spacer/>
          </v-col>
          <v-col align="center" align-self="center" cols="auto">
            <v-col align-self="center" cols="auto">
              <v-img :src="logoSrc"
                  contain
                  width="160px"
                  class="px-0 v-col-auto"/>
            </v-col>
<!--            <v-col align-self="center" cols="auto">-->
<!--              Inputlock-->
<!--            </v-col>-->
          </v-col>
          <v-col align="end" align-self="center">
            <v-col v-if="isWalletConnected" align-self="center" cols="auto" class="pr-4">
              <UserProfileView/>
            </v-col>
            <v-col v-if="!isWalletConnected" align-self="center" cols="auto" class="pr-4">
              <v-btn rounded color="primary" variant="outlined" @click="connect()">
                Connect
              </v-btn>
            </v-col>
          </v-col>
        </v-row>

      </v-app-bar>
      <div class="">
        <OrganisationManager v-if="isWalletConnected" class=""/>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import OrganisationManager from './components/OrganisationManager.vue'
import UserProfileView from '@/components/UserProfileView.vue'
import dao from './OrganisationContractDAO'

export default {
  name: 'App',
  mounted () {
    document.title = "inputlock";
    dao.initWeb3()
    this.timer = setInterval(async () => {
      this.isWalletConnected = await dao.isWalletConnected();
      console.log('update connected state: ' + this.isWalletConnected)
    }, 2000)
  },
  data () {
    return {
      logoSrc: require('@/assets/logo-with-text.png'),
      betaSrc: require('@/assets/beta.png'),
      timer: null,
      isWalletConnected: false
    };
  },
  components: {
    UserProfileView,
    OrganisationManager
  },
  beforeUnmount () {
    clearInterval(this.timer)
  },
  methods: {
    connect () {
      dao.open()
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;

}

.button-text-no-caps .v-btn__content {
  text-transform: none !important;
}
</style>
