<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
              disable-pagination
              :items-per-page="0"
              :items="adminProposalsListEntries"
              :headers="adminAddProposalsListHeaders">
            <template v-if="!title" v-slot:headers="{ columns }">
              <tr>
                <template v-for="column in columns" :key="column.key">
                  <!--  no header  -->
                </template>
              </tr>
            </template>
            <template #bottom></template>
            <template v-slot:body="{ items }">
              <tr v-for="item in items" :key="item.adminAddress">
                <td>
                  <div class="d-flex align-center">
                  <span class="text-no-wrap">
                    <v-btn color="primary" variant="outlined" class="mr-3 button-text-no-caps" :disabled="item.userHasVoted === true" @click="$emit( 'approve-proposal', item.proposalId)">
                      <v-icon icon="mdi-check"/>Approve ({{ item.approvalCount }}/{{ organisationEntry?.effectivelyRequiredSignaturesForAdminOperations }})
                    </v-btn>
                           {{ itemPrefix }}
                    <v-icon large icon="mdi-shield-account"/>
                    {{ item.adminsUserProfileName }}
                    <span style="opacity: 0.5;">{{ item.adminsUserProfileEmail }}</span>
                  </span>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      adminAddProposalsListHeaders: [
        { title: this.title, value: 'adminsUserProfileName' },
      ]
    };
  },
  props: {
    title: String,
    adminProposalsListEntries: Array,
    organisationEntry: null,
    itemPrefix: String
  },
  methods: {},
};
</script>

<style scoped>
</style>