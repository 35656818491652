<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
              disable-pagination
              :items-per-page="0"
              :items="signersListEntries"
              :headers="signersListHeaders">
            <template v-if="!title" v-slot:headers="{ columns }">
              <tr>
                <template v-for="column in columns" :key="column.key">
                  <!--  no header  -->
                </template>
              </tr>
            </template>
            <template #bottom></template>
            <template v-slot:body="{ items }">
              <tr v-for="item in items" :key="item.signerAddress">
                <td>
                  <v-tooltip text="Tooltip">
                    <template v-slot:activator="{ props }">
                      <v-row v-bind="props" class="text-no-wrap ml-0">
                        <!--     <span class="d-flex align-center">-->
                        <v-icon large icon="mdi-account"/>
                        <span class="ml-2">{{ item.userProfileName }}</span>
                        <span class="ml-2" style="opacity: 0.5;">{{ item.userProfileEmail }}</span>
                        <!--                  </span>-->
                      </v-row>
                    </template>
                    <p v-if="item.userProfileName !== item.signerAddress"> {{ item.userProfileName }}</p>
                    <p>{{ item.userProfileEmail }}</p>
                    <p>{{ item.signerAddress }}</p>
                  </v-tooltip>

                </td>
                <td>
                  <div>
                    <v-btn flat>
                      <v-icon large color="primary" icon="mdi-dots-vertical"/>
                      <v-menu activator="parent">
                        <v-list>
                          <v-list-item v-if="item.isOwnUser" :key="1" :value="1" @click="$emit('removeMyselfAsSignerWithoutApproval', item.signerAddress)">
                            <v-list-item-title>Remove Yourself</v-list-item-title>
                          </v-list-item>
                          <v-list-item v-else :key="2" :value="2" @click="$emit( 'proposeSignerRemoval',item.signerAddress)">
                            <v-list-item-title>Propose Removal</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  components: {},
  data () {
    return {
      signersListHeaders: [
        { title: 'Signer', value: 'userProfileName' },
        { title: '', align: 'end', value: 'userProfileName', width: '32px' },
      ]
    };
  },
  props: {
    signersListEntries: Array,
    title: String
  },
  methods: {},
};
</script>

<style scoped>
</style>